import * as React from "react"
import ordersApi from "./index"

const useOrderApi = () => {
 
    const [error, setError] = React.useState()
    const [isSaving, setIsSaving] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)

    const reset = ()=> {
        
        setIsSuccess(false)
        setError(undefined)
    }


    const save =  async (order)  => {

        setIsSaving(true)
        setError(undefined)
        ordersApi().create(order).then(response => {
            if(response.status === 200 || response.status === 204)
            {
                setIsSuccess(true)
            }

        }).catch((error)=>{
            setError(`Ocurrio un error al guardar la orden ${order.orderId}`)
        }).finally(()=>{
            setIsSaving(false)
        })

    }

    return{
        save, 
        reset,
        error, 
        isSaving, 
        isSuccess
    }
 }

 export default useOrderApi