import * as React from "react"
import {Form , Input , Button , Row , Col, Alert} from "antd"
import PageLayout from "../../components/pageLayout";
import { Fragment } from "react";

import useOrderApi from "../../services/orders/useOrderApi";


const NewOrderPage = () => {

    const {error, isSaving, isSuccess, save} = useOrderApi()

    const onSubmit = (values) => {
        save(values)
    }

    return(<PageLayout>

        <h2>Nueva orden</h2>
        {error ? <Alert type="error" showIcon closable>{error}</Alert>:null}
        {isSuccess ? <Alert type="success" showIcon closable>Orden guardada correctamente</Alert>:null}
        <Form layout="vertical" onFinish={onSubmit}>

            <Form.Item label="Origen" name="origin">
                <Input placeholder="Identificador del cliente" />
            </Form.Item>
            <Form.Item label="N° Pedido" name="orderId">
                <Input placeholder="Número de orden o pedido" />
            </Form.Item>
            <Form.Item label="SKU" name="sku">
                <Input placeholder="Identificador único de producto"/>
            </Form.Item>
            <Form.Item label="Producto" name="product">
                <Input placeholder="Descripción del producto" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" loading={isSaving} htmlType="submit">Crear</Button>
            </Form.Item>
        </Form>

    </PageLayout>)
}

export default NewOrderPage