import { api } from "../apiBase"


const orders = () => 
{
    /*
    api.interceptors.request.use(config => {
        config.headers =  {
            'Authorization': `Bearer ${token}`
        }
        return config; 
    })*/
    const get = (order, pagination, status) => { 
                       
        let limit  =pagination ? pagination.limit : 50
        let page = pagination ?  pagination.page : 0
        let query = `/orders?page=${page}&limit=${limit}`
        if(status)
        {
            query =  `${query}&status=${status}`
        }
        return api.get(query)
    }
    const detail = (id) => {
        api.get(`/orders/${id}`)
    }
    const count = (order, status) => {
    
        let query = `/orders/count`
        if(status)
        {
            query =  `${query}?status=${status}`
        }
        return api.get(query)
       
    }
    const save = (product) => {
       return  api.put("/orders")
    }
    const create = (order) => {
       return  api.post("/orders", order)
    }
    const remove  = (id) => {
        return api.delete(`/orders/${id}`)
    }
    return  {get, detail, count, save, remove, create}
}


export default orders 